import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import './payment-form.scss'
import { useEffect } from 'react';



const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#ccc",
      color: "#ccc",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};


const CheckoutForm = ({ clientSecret, increaseStep }) => {

  const [error, setError] = useState(false)
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true)
    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),

      },
    });

    if (error) {
      setLoading(false)
      setError(true);
    } else {
      increaseStep()
      setLoading(false)
    }

  }

  useEffect(() => {


    const timer = setTimeout(() => {
      setError(false)

    }, 5000);
    return () => {
      clearTimeout(timer);
    }
  }, [error])

  const handleGtag = () => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'wedding_checkout_completion_step_annual', { 'event_category': 'ecommerce', 'event-label': 'Completed' });

    }
  }


  return <div>

    <form onSubmit={handleSubmit}>

      <CardElement id='card-element' options={CARD_ELEMENT_OPTIONS} />
      <button className="btn-pay" style={{marginTop: '15px'}} disabled={!stripe} onClick={() => handleGtag()}>{loading ? (
        <div style={{color: 'white'}} data-uk-spinner></div>
      ) : 'Pay'}</button>
    </form>
    {error ? <p style={{ color: 'red' }}>Something went wrong, you may try again or please contact our support team!</p> : ''}
  </div>;
};

export default CheckoutForm;
