import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "./wedding-direct-debit.scss";
import moment from "moment"
import { useProductsContext } from '../../context/products-context';
import { checkTheme } from '../../utils/informations'


const WeddingDirectDebit = () => {

    const {premiumFundingDetails, SelectedProducts,SelectedProductsUnformatted, handleDate, typeOfPayment, selectTypeOfPayment,handleDateOfBirth, dateOfBirthUnformatted, handleDirectDebit, directDebitDetails:{firstname, preferredPaymentDate, surname, sortCode, accountNo, bankName, bankPostcode, bankAddress1, bankAddress2, bankTown, preferredPaymentDateUnformatted}, completeQuoteOrder, checkBankData, directDebitLoading, bankValidationCode, stripeClientSecret, purchaseLoading, handlePreferredPaymentDay, directDebitError} = useProductsContext()
    
    const [bankValidationError, setBankValidationError] = useState(false)


    
    


    useEffect(() => {
      if(sortCode && accountNo) {
        setBankValidationError(false)
      }
    }, [sortCode, accountNo])

    const {ShowPaymentTypeSelector, MinDepositAmount, MonthlyPayable, DepositTerm,APR, TotalCost, PremiumFinance, TotalAmountPayable
} = premiumFundingDetails

  const [openModal, setOpenModal] = useState(false);

  return (
   
      <div className='direct-debit-container'>
        <div className='secton-title-dd padding-top'>Instruction to your bank or building society to pay direct debit</div>
        <div className='agreement'>
            <p className='accept'>I accept a loan to pay for the Total Amount Payable mentioned opposite.</p>
            <div className='prices'>
                <div className='row'>
                    <div>Total Premiums</div>
                    <div >£{TotalCost.toFixed(2)}</div> 
                </div>
                <div className="row">
                    <div>Less Deposit to {checkTheme().pageTitle}.com</div>
                        <div >£{MinDepositAmount.toFixed(2)}</div>
                </div>
                <div className="row">
                    <div>Premium on Finance</div>
                    <div >£{PremiumFinance.toFixed(2)}</div>
                </div>
                <div className="row">
                    <div>Add Installment charge at {APR}%</div>
                    <div >£0.00</div>
                </div>
                <div className="row">
                    <div>TOTAL AMOUNT PAYABLE</div>
                    <div >£{TotalAmountPayable.toFixed(2)}</div>
                </div>
                <div className="row">
                    <div>MONTHLY AMOUNT PAYABLE (payable by {DepositTerm} equal monthly instalments)</div>
                    <div >£{MonthlyPayable.toFixed(2)}</div>
                </div>
            </div>
                 
        </div>
      
         <div className='secton-title-dd padding-top'>DECLARATION</div>
          <div>By signing this declaration, I the customer declare that:</div>
          <ol>
              <li>The details I have provided in accepting this loan are true and complete and may be verified by you.</li>
              <li>I understand to notify {checkTheme().pageTitle}.com Limited of any changes in my circumstances which may affect this credit transaction. </li>
              <li>I have given my express consent to the monitoring or recording or telephone calls referred to overleaf, including any made or carried out at the date of signing this application.</li>
              <li>I am 18 years of age or over.</li>
          </ol>

  <div className='key-info padding-top'>
    <p><span class="pfHighlight ">KEY INFORMATION</span> - Upon failure by the Debtor to do or refrain from doing anyting required under this Agreement, the Debtor will be required to make the following payments to the creditor:</p>
    <p>Default Letter £25 : Unpaid Cheques £25 : Unpaid Direct Debits £25 : Recalling Standing Orders £10 : Appointing Collection Agency £50</p>
    <br />
    <p>We may charge interest at a rate not exceeding the rate of interest charged under this Agreement, on all sums overdue under this Agreement.<br /> We will charge interest from the due date for the payment until the payment is received in cleared funds. Interest is calculated on a daily basis, and is payable on demand.</p>
    </div>
    
<div className='secton-title-dd padding-top'>Instruction to your bank or building society to pay direct debit</div>
  {/**detaliile puse in form */}
 <ol>
    <li>Name(s) of account holder(s): {firstname + ' ' + surname}</li>
    <li>
        Name and full postal address of your Bank or Building Society branch:
        <div>
            <div>To: The manager</div>
            <div>Bank or Building Society Name: {bankName}</div>
            <div>Addess: {bankAddress1 + ' ' + bankAddress2}</div>
            <div>Town: {bankTown}</div>
            <div>Postcode: {bankPostcode}</div>
        </div>
    </li>
    <li>Branch Sort Code: {sortCode}</li>
    <li>Preferred payment date after 1st instalment: {preferredPaymentDate}</li>
    <li>Bank or Building Society account number: {accountNo}</li>
    <li>
        Instruction to your Bank or Building Society. <br />
        Please pay Premfina Direct Debits detailed in this instruction subject to the safeguards offered by The Direct debit Guarantee
    </li>
  </ol>

   
         <p className='gray-heading'>By accepting these terms, you are acknowledging this document and you are signing it electronically</p>
            
           
             <div className='secton-title-dd'>
                    NO ACKNOWLEDGEMENT REQUIRED      Bank and Building Societies may not accept Direct Debit Instructions for some types of account.
                </div>
                <div className='paragraph-padding gray-heading' >
                    I understand that this instruction may remain the originator Premfina and details may be passed to my Bank/Building Society electonically.
                    <br />
                    You will receive the full terms and conditions in the post.
                </div>
                <div className='paragraph-padding'>
                    <div className=' blue-heading'>
                        Charges on Default
                    </div>
                    <div className='paragraph-padding '>
                        The Debtor may be charged, on demand, all of our costs, charges and expenses (together with all legal costs recoverable against the Debtor) incurred by us in enforcing our rights under your Credit Agreement, including (in the event of any debt collection action) a debt collection administration fee of £25.
                    </div>
                    <div className='paragraph-padding '>
                        Failing to keep up to date with payments under this Credit Agreement may result in your insurance cover being terminated and may impact on your credit rating.
                    </div>
                    <div className='paragraph-padding '>
                        In the event of your default, any available pro-rata refund of the policy premium, will be applied to your Credit Agreement.
                    </div>
                    <div className='paragraph-padding '>
                        Interest at the rate mentioned on any sum payable to us, under a Credit Agreement, will be payable from its due date to the date of actual payment.
                    </div>
                    <div className='paragraph-padding  blue-heading'>
                        Important Information
                    </div>
                    <div className='paragraph-padding '>
                        This is a Credit Proposal application for the provision of a loan to fund the purchase of an insurance product.
                    </div>
                    <div className='paragraph-padding '>
                        After signing this proposal, you will receive the full Credit Agreement direct from the Creditor, which you should sign and return.
                    </div>
                    <div className='paragraph-padding '>
                        The Creditor will carry out an assessment of this application via a Credit Reference Agency. By your signature below, You are giving authority for this search to be performed and by applying for this credit, you consent to your data being passed to the Creditor so they may assess your application.
                    </div>
                    <div className='paragraph-padding '>
                        If you wish to cancel the Credit Agreement within 14 days of receipt of the documentation, you will have to pay the Motor Dealer / Broker, in full, for any insurance’s that were to be funded by the Credit Agreement.
                    </div>
                    <div className='paragraph-padding '>
                        Using a Credit Agreement may result in the total cost of the products you are buying being more expensive than if you paid by another method.
                    </div>
                    <hr  />
                    <div className='paragraph-padding  blue-heading'>
                        Early Settlement
                    </div>
                    <div className='paragraph-padding '>
                        You can settle your Credit Agreement at any time by giving notice in writing and paying off the amount you owe under the agreement.
                    </div>
                </div>
                </div>
               
  )
}

export default WeddingDirectDebit