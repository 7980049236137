import React, { useEffect, useState } from 'react'
import moment from "moment"
import Modal from '../modal/modal';
import './wedding-payment-frequency.scss';
import { useProductsContext } from '../../context/products-context';
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io'
import WeddingDirectDebit from '../wedding-direct-debit/wedding-direct-debit';
import { finalStepVehicleCheckout } from '../../utils/informations';

const WeddingPaymentFrequency = () => {
  const { premiumFundingDetails, selectTypeOfPayment, completeQuoteOrder, typeOfPayment, purchaseLoading, setStep, stripeClientSecret, decreaseStep, dateOfBirthUnformatted } = useProductsContext()
  const { ShowPaymentTypeSelector, MinDepositAmount, MonthlyPayable, DepositTerm, APR, TotalCost } = premiumFundingDetails
  const [openModal, setOpenModal] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);

  const dob = moment(dateOfBirthUnformatted).format('YYYY/MM/DD')

  const checkDOB = (dob) => {

    let today = new Date();
    let birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age

  }

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else if (!openModal) {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const completeOrder = async (e) => {

    setQuoteLoading(true);
    if (typeOfPayment === 'directDebit') {

      await completeQuoteOrder()
      // setOpenModal(true)
      window.scrollTo(0, 0)
      e.stopPropagation()
      document.body.style.overflow = "unset";

    } else if (typeOfPayment === 'fullPayment') {
      await completeQuoteOrder()
    }
    setQuoteLoading(false)

    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'wedding_checkout_second_step', { 'event_category': 'ecommerce', 'event-label': 'Completed' });

    }
  }

  const goBack = () => {

    // if the products array are empty, redirect thse user to the first step    
    let weddingProducts = [];
    weddingProducts = Object.keys(JSON.parse(window.sessionStorage.getItem("weddingProducts")) || {})
    if (typeof window !== "undefined" && window.location.hash == "#loaded" && !weddingProducts.length) {

      setStep(0)


    }
    else {
      decreaseStep();

    }
  }
  return (

    <div className='payment-frequency-container padding-top'>
      <h3 className='heading-pf'>
        Payment Frequency
      </h3>

      <div className="premium-funding">

        <div className={typeOfPayment === "fullPayment" ? 'dark-payment' : 'light-payment'} data-type="fullPayment" onClick={selectTypeOfPayment}>
          <h4 className="no-margin-bottom" data-type="fullPayment">Annual</h4>
          <div className="small-padding-bottom" data-type="fullPayment">Full Payment Today</div>
          <div className='break-line' data-type="fullPayment"></div>
          <div className="small-padding-top" data-type="fullPayment">Pay £{TotalCost.toFixed(2)} now</div>
          <span >
            {/* <span className='small-circle'>c</span> */}
          </span>
          <input type="radio" className='pay-input cursor-pointer' value='fullPayment' onChange={selectTypeOfPayment} checked={typeOfPayment === "fullPayment" ? true : false} name='payment' />
        </div>

        <div className={typeOfPayment === "directDebit" ? 'dark-payment' : 'light-payment'} data-type="directDebit" onClick={selectTypeOfPayment}>
          <h4 data-type="directDebit" className="monthly-title">Monthly</h4>
          <div data-type="directDebit">Deposit of £{MinDepositAmount.toFixed(2)}</div>
          <div data-type="directDebit">Then</div>
          <div data-type="directDebit">{DepositTerm} monthly payments of £{MonthlyPayable.toFixed(2)}</div>
          <button className="finance-disclosure" onClick={() => setOpenModal(true)}>Finance Disclosure</button>
          <input type="radio" className='pay-input cursor-pointer' value='directDebit' name='payment' onChange={selectTypeOfPayment} checked={typeOfPayment === "directDebit" ? true : false} />
        </div>

      </div>

      {openModal && (


        <Modal
          openModal={setOpenModal}
          isModalOpen={openModal}
          // paymentFunction={completeOrder}
          paymentButtonLabel={purchaseLoading && !stripeClientSecret ? (
            <span data-uk-spinner="ratio: .5" className={`button-spinner `} ></span>
          ) : 'Complete payment'}
        >
          <WeddingDirectDebit />
        </Modal>


      )
      }
      {
        typeOfPayment === "fullPayment" ?
          <div className='buttons-container display-flex padding-top padding-bottom uk-margin-top'>
            <button className="back" onClick={goBack}> <IoIosArrowRoundBack size={24} /> Go back</button>
            <button type="button" className="green-btn" onClick={() => completeOrder()} disabled={quoteLoading ? true : false}>
              {
                quoteLoading ? <div className="spinner-quote" data-uk-spinner></div> : <span>Go to payment<IoIosArrowRoundForward size={24} /></span>
              }

            </button>
          </div>
          :
          <div></div>
      }
    </div>
  )
}

export default WeddingPaymentFrequency