import React, { useEffect, useState } from "react";

import { useProductsContext } from "../../context/products-context";
import Button from "../button/button";
import Modal from "../modal/modal";
import "./wedding-eligibilities.scss";
import { navigate } from "gatsby"
import { loadStripe } from "@stripe/stripe-js";
import { IoIosArrowRoundBack } from 'react-icons/io'
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../checkout-form/CheckoutForm";
import IddComponent from "../idd-component/idd-component";
import x_mark from "../../assets/x-red.png";
import checked_icon from "../../assets/checkedIconGreen.webp";
import { MdOpenInNew } from 'react-icons/md'

import { StaticImage } from 'gatsby-plugin-image'
import Mastercard from '../../assets/Mastercard.webp'
import Visa from '../../assets/Visa.webp'
import Amex from '../../assets/American-Express.webp'
import ApplePay from '../../assets/Apple_Pay.webp'
import GooglePay from '../../assets/Google_Pay.webp'
import WeddingPaymentFrequency from "../wedding-payment-frequency/wedding-payment-frequency";
import DirectDebitDetails from "../direct-debit-details/direct-debit-details";
import WeddingDebitDetails from "../wedding-debit-details/wedding-debit-details";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

const WeddingEligibilities = ({ data, clearCart }) => {
  const {
    additionalOptions: { marquee, sword },
    updateAdditionalOptions,
    finIddResult,
    decreaseStep,
    changeWeddingIndexAfterRedirect,
    premiumFundingDetails,
    completeQuoteOrder,
    premiumFundingDetailsUpdate,
    purchaseLoading,
    purchaseCode,
    stripeClientSecret,
    typeOfPayment,
    increaseStep,
    completeQuoteError,
    returnToProducts,
    finIDDfetchResult,
    questions,
  } = useProductsContext();
  const [openModal, setOpenModal] = useState(false);

  const [storedRedirectFlag, setStoredRedirectFlag] = useState(JSON.parse(sessionStorage.getItem('redirectFlagEvent')))

  var premiumFundingDetailsInitBasket = {};
  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("premiumFundingDetailsInitBasket")
  ) {
    premiumFundingDetailsInitBasket = JSON.parse(sessionStorage.getItem("premiumFundingDetailsInitBasket"));

  }




  useEffect(() => {

    var premiumFundingDetailsInitBasket = {};
    if (typeof window !== "undefined" && window.sessionStorage.getItem("premiumFundingDetailsInitBasket")) {
      
      premiumFundingDetailsInitBasket = JSON.parse(sessionStorage.getItem("premiumFundingDetailsInitBasket"));
      premiumFundingDetailsUpdate(premiumFundingDetailsInitBasket.premiumFundingDetails);
    
    }

    if (premiumFundingDetails.ShowPaymentTypeSelector === false || (premiumFundingDetails.ShowPaymentTypeSelector === false || premiumFundingDetailsInitBasket?.premiumFundingDetails?.ShowPaymentTypeSelector === false) && typeof window !== "undefined" && window.location.hash === "#loaded") {
      completeQuoteOrder();
    }
  }, []);


  const options = {
    clientSecret: stripeClientSecret,
  };


  const previousStep = () => {

    decreaseStep();
  }



  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else if (!openModal) {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);


  const [weddingQuestions, setWeddingQuestions] = useState(["Do you confirm that you do not already have cover in place, to protect you against the costs of repairs to or replacement of tyres on your vehicle, that are within the legal limits and have suffered accidental or malicious damage?",
    "Do you confirm that you will not be using the covered vehicle for Hire and Reward purposes? ( Hire & Reward means the use of the Vehicle as a Taxi, Driving School, Courier or racing, pace-making, speed testing, reliability trials, rallying, or Vehicles used for any other competitive event)",
    "Do you confirm that you would see a benefit in being reimbursed for the costs of repairs following accidental or malicious damage to your tyres?"]);

  const increaseMarkedQ = (e) => {
    //console.log("..." + e.target.id)
    let copy = weddingQuestions.filter((question, index) => e.target.id != index);
    // console.log(copy)
    setWeddingQuestions(copy)
    //console.log("............" + weddingQuestions)
  }



  return (
    <div className="wedding-eligibilities-container  padding-top padding-bottom">
      <div className="wedding-eligibilities" id="legal">

        <div className="uk-width-1-2@l uk-width-1-2@m wedding-summary-card">
          <div className="summary-information" id="wedding-ins-card-margin">
            {/* <h4>
              <strong>Your products</strong>
            </h4> */}

            <div className="summary-cards-container" id="wedding-insurance-card">
              <div className="summary-information-card" id="wedding-insurance-card-bg">
                <div className="summary-card-subtitle" id="wedding-ins-card-title">{data?.Title}</div>

                <div className="wedding-ins-card-display">
                  <div className="left-col-insurance">
                    {/* <div className="summary-card-button-container">
                    <Button btnType="secondary" clickAction={clearCart}>
                      Remove insurance
                    </Button>
                    </div> */}

                    <div className="summary-card-price" id="wedding-ins-card-price">
                      £{data?.Price}
                      <div className="tooltip-container">
                        <span className="wedding-ins-card-subprice">inc IPT at 12%</span>
                        {/* <span
                        className=" info uk-margin-small-right"
                        data-uk-icon="info"
                        data-uk-tooltip="title: Insurance Premium Tax (IPT) is a tax on general insurance premiums; pos: bottom">
                      </span> */}

                      </div>
                    </div>

                    {/* <div className="btn-continue" id="policy-btn">
                      <button className="wedding-ins-card-policy-btn" onClick={() => setOpenModal(true)}><MdOpenInNew /> IPID</button>
                    </div>
                    {openModal && (
                      <Modal
                        openModal={setOpenModal}
                        isModalOpen={openModal}

                      ><IddComponent data={finIddResult} /></Modal>
                    )} */}

                    <div className="cancellation-complaints" id="cancellation-complaints-view">

                      <div className="btn-continue" id="policy-btn">

                        <a
                          href={data?.KeyFacts}
                          className="KF"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <MdOpenInNew />&#8198;View IPID
                        </a>

                      </div>

                      {/* {openModal && (
                        <Modal
                          openModal={setOpenModal}
                          isModalOpen={openModal}

                        ><IddComponent data={finIddResult} /></Modal>
                      )} */}

                      <a href={data?.TC}
                        className="TC"
                        target="_blank"
                        rel="noreferrer">
                        <MdOpenInNew /> Policy Wording
                      </a>

                      <a href="/cancellation-and-claims-process" target="_blank">
                        <MdOpenInNew /> Cancellation & Claims Process
                      </a>

                      <a href="/complaints-procedure" target="_blank">
                        <MdOpenInNew /> Complaints Procedure
                      </a>
                    </div>
                  </div>

                  <div className="right-col-products">
                    <h2 className="additional-insurance-products">Additional extras</h2>
                    <div className="insurances">
                      {
                        (data?.CodeTemplate?.includes('5M')) ?
                          <div className="insurances-display">
                            <img src={checked_icon} className="checked-icon" alt="product-benefits"></img>
                            <label className="insurance-added">Public Liability Cover</label>
                            <p className="liability-cover-amount">Covered up to £5,000,000</p>
                          </div> :
                          (data?.CodeTemplate?.includes('2M')) ?
                            <div className="insurances-display">
                              <img src={checked_icon} className="checked-icon" alt="product-benefits"></img>
                              <label className="insurance-added">Public Liability Cover</label>
                              <p className="liability-cover-amount">Covered up to £2,000,000</p>
                            </div> :
                            <div className="insurances-display">
                              <img src={x_mark} className="not-checked-icon" alt="not-covered-list"></img>
                              <label className="no-insurance">Public Liability Cover</label>
                            </div>
                      }
                      {
                        (data?.CodeTemplate?.substr(data?.CodeTemplate.length - 1, 1) == '2') ?
                          <div className="insurances-display">
                            <img src={checked_icon} className="checked-icon" alt="product-benefits"></img>
                            <label className="insurance-added">Marquee Cover</label>
                          </div> :
                          (data?.CodeTemplate?.substr(data?.CodeTemplate.length - 1, 1) == '1') ?
                            <div className="insurances-display">
                              <img src={checked_icon} className="checked-icon" alt="product-benefits"></img>
                              <label className="insurance-added">Marquee Cover</label>
                            </div> :
                            <div className="insurances-display">
                              <img src={x_mark} className="not-checked-icon" alt="not-covered-list"></img>
                              <label className="no-insurance">Marquee Cover</label>
                            </div>
                      }
                      {
                        (data?.CodeTemplate?.substr(data?.CodeTemplate.length - 2, 1) == '1') ?
                          <div className="insurances-display">
                            <img src={checked_icon} className="checked-icon" alt="product-benefits"></img>
                            <label className="insurance-added">Ceremonial Swords Cover</label>
                          </div> :
                          <div className="insurances-display">
                            <img src={x_mark} className="not-checked-icon" alt="not-covered-list"></img>
                            <label className="no-insurance">Ceremonial Swords Cover</label>
                          </div>
                      }
                      {
                        (data?.CodeTemplate?.includes('COV') && !data?.CodeTemplate?.includes('NOCOV')) ?
                          <div className="insurances-display">
                            <img src={checked_icon} className="checked-icon" alt="product-benefits"></img>
                            <label className="insurance-added">COVID Cover</label>
                          </div> :
                          <div className="insurances-display">
                            <img src={x_mark} className="not-checked-icon" alt="not-covered-list"></img>
                            <label className="no-insurance">COVID Cover</label>
                          </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="terms-header">
                <div className="wedding-questions-title">Terms & conditions</div>
                <div className="insurances-display">
                  <span onClick={() => setOpenModal(true)} className='business-terms'> <MdOpenInNew />&#8198;Our Terms of Business</span>
                  {openModal && (


                    <Modal openModal={setOpenModal}
                      isModalOpen={openModal}
                    // data={finIDDfetchResult}
                    >
                      <IddComponent data={finIDDfetchResult} />
                    </Modal>
                  )}
                </div>
              </div>

              {
                questions ? questions.map((item, index) => {
                  return (
                    <div className={'single-question'}>
                      <label>
                        <input
                          id={index}
                          type="checkbox"
                          checked=""
                          onChange={(e) => increaseMarkedQ(e)}
                        />
                        <span className="question">{item}</span>
                      </label>
                    </div>
                  )

                }) : ""
              }

              {
              }
              {
                premiumFundingDetails.ShowPaymentTypeSelector && !stripeClientSecret && !purchaseCode ?

                  <WeddingPaymentFrequency />

                  : ""
              }
              {/* when payment is done, hide weddind debit details */}
              {
                typeOfPayment === 'directDebit' && !purchaseCode ? (
                  <WeddingDebitDetails />

                ) : ""

              }

            </div>

            {
              !premiumFundingDetails.ShowPaymentTypeSelector || stripeClientSecret || (purchaseCode && !stripeClientSecret && typeOfPayment === "directDebit") ?
                <div>
                  <div className="summary-final-price" id="final-price-width">
                    <div className="wedding-payment-title">Payment details</div>
                    {/* <div className="wedding-payment-title">£{data?.Price}</div> */}
                  </div>
                  {
                    purchaseLoading ? (

                      <div style={{ color: '#77DD77', display: 'block', textAlign: 'center' }} data-uk-spinner></div>

                    ) : ''
                  }

                  {completeQuoteError ? <p>Something went wrong, you may try again or please contact our support team!</p> : ''}

                  {


                    stripeClientSecret ? <div className="payment-form1" id="payment-form-width">
                      <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm clientSecret={stripeClientSecret} increaseStep={increaseStep} />

                        <div className="no-padd-left-payment">
                          <div className="payment-methods no-padding-left">
                            <StaticImage src='../../assets/stripe_powered2.png' alt="stripe" className="stripe" width='350' height={110} />
                            <div className="payment-methods-media uk-flex uk-flex-nowrap">
                              <img src={Mastercard} alt="Mastercard" className="payment-card2"></img>
                              <img src={Visa} alt="Visa" className="payment-card2"></img>
                              <img src={Amex} alt="American Express" className="payment-card2"></img>
                              <img src={ApplePay} alt="Apple Pay" className="payment-card2"></img>
                              <img src={GooglePay} alt="Google Pay" className="payment-card2"></img>
                            </div>
                          </div>
                        </div>

                      </Elements>
                    </div> : ''
                  }
                  <button className="back" onClick={previousStep}> <IoIosArrowRoundBack size={24} /> Go back</button>

                </div> : ""
            }



            {/* <div className="summary-final-price" id="final-price-width">
              <div className="wedding-payment-title">Payment details</div>
              <div className="wedding-payment-title">£{data?.Price}</div>
            </div> */}

          </div>


        </div>
      </div>


    </div>
  );
};

export default WeddingEligibilities;
