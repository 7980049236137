import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import { FaSearch, FaSpinner } from 'react-icons/fa'
import moment from "moment"
import "./wedding-debit-details.scss";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io'
import { useProductsContext } from '../../context/products-context';
import "react-datepicker/dist/react-datepicker.css";

const WeddingDebitDetails = () => {

    const { typeOfPayment, decreaseStep, handlePreferredPaymentDay, dateOfBirthUnformatted, checkBankData, handleDateOfBirth, directDebitLoading, directDebitDetails: { firstname, preferredPaymentDate, surname, sortCode, accountNo, bankName, bankPostcode, bankAddress1, bankAddress2, bankTown, preferredPaymentDateUnformatted }, handleDirectDebit, directDebitError, completeQuoteOrder, accountNoError, sortCodeError } = useProductsContext();

    const [bankValidationError, setBankValidationError] = useState(false)
    const [error, setError] = useState(false)
    const [dobError, setDobError] = useState(false)
    const [loading, setLoading] = useState(false);
    const dob = moment(dateOfBirthUnformatted).format('YYYY/MM/DD')

    const getBankDetails = () => {
        if (!sortCode && !accountNo) {
            setBankValidationError(true)
        } else if (!bankValidationError) {
            checkBankData()
        }
    }

    const checkDOB = (dob) => {

        let today = new Date();
        let birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age

    }
    const checkDirectDebitInput = () => {


        if (firstname && surname && sortCode && accountNo && bankName && bankPostcode && bankAddress1 && bankTown) {

            if (checkDOB(dob) >= 18) {
                return true
            } else {
                setDobError(true)
                return false
            }
        } else {
            setError(true)
            return false
        }
    }

    const completeDirectDebit = async (e) => {

        if (checkDirectDebitInput()) {

            setLoading(true)
            await completeQuoteOrder()
            window.scrollTo(0, 0)
            // e.stopPropagation()
            document.body.style.overflow = "unset";
        }
        else{
            setLoading(false)
        }

        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'wedding_checkout_completion_step_monthly', { 'event_category': 'ecommerce', 'event-label': 'Completed' });
      
          }
    }
    return (

        <>
            <div className="direct-debit-details-container">
                <p className='payment-frequency-heading'>Direct Debit details</p>
                <div className='direct-debit-flex'>
                    <div className="half-width">
                        <label className='datepicker-title'>Please enter Date of Birth</label>
                        <ReactDatePicker
                            wrapperClassName="date-picker-pf" selected={dateOfBirthUnformatted} dateFormat="dd/MM/yyyy" onChange={handleDateOfBirth} maxDate={moment().toDate()}
                        />
                        {dobError ? <p className='error__message'>You must be over 18 years old for monthly payment! Please select full payment option.</p> : ''}
                    </div>

                    <div className='date-container half-width'>
                        <label className='datepicker-title'>Preferred payment date</label>
                        <ReactDatePicker
                            wrapperClassName="date-picker-pf" selected={preferredPaymentDateUnformatted} dateFormat="dd/MM/yyyy" onChange={handlePreferredPaymentDay}
                        />
                    </div>
                </div>
                <form onSubmit={e => e.preventDefault()}>

                    <div className="direct-debit-flex">
                        <div className="half-width">
                            <label htmlFor="firstname">Account Holder's First Name </label>
                            <input type="text" id='firstname' className={`direct-details-input uk-form-width-medium uk-input ${error ? 'error-border' : ''}`} value={firstname} onChange={handleDirectDebit} name='firstname' />
                        </div>


                        <div className="half-width">
                            <label htmlFor="surname">Account Holder's Surname </label>
                            <input type="text" id='surname' value={surname} className={`direct-details-input uk-form-width-medium uk-input ${error ? 'error-border' : ''}`} onChange={handleDirectDebit} name='surname' />
                        </div>
                    </div>

                    <div className="direct-debit-flex">
                        <div className="half-width">
                            <label htmlFor="sortcode">Sort Code (NNNNNN) </label>
                            <input type="text" id='sortcode' value={sortCode} pattern='[0-9]*' className={`direct-details-input uk-form-width-medium uk-input ${error ? 'error-border' : ''}`} onChange={handleDirectDebit} name='sortCode' />
                            {
                                sortCodeError ? <span className="error__message">The text must contain 6 characters</span> : ""
                            }
                        </div>

                        <div className="half-width">
                            <label htmlFor="accountNo">Account Number </label>

                            <span className='accountNo-container'>
                                <input type="text" id='accountNo' value={accountNo} onChange={handleDirectDebit} pattern='[0-9]*' name='accountNo' className={`direct-details-input uk-form-width-medium uk-input ${error ? 'error-border' : ''}`} />
                                {
                                    accountNoError ? <span className="error__message">The text must contain 8 characters</span> : ""
                                }
                                <span className="icon-container" >

                                    <span className="search-icon-details" onClick={getBankDetails}>
                                        {directDebitLoading ? <FaSpinner size={24} className="spiner-animation" /> : <FaSearch className="" size={24} />}
                                    </span>
                                </span>
                            </span>

                        </div>
                    </div>

                    <>
                        <div className="direct-debit-flex">
                            <div className="half-width">
                                <label htmlFor="bankName">Bank Name </label>
                                <input type="text" id='bankName' value={bankName} onChange={handleDirectDebit} name='bankName' className={`direct-details-input uk-form-width-medium uk-input ${error ? 'error-border' : ''}`} />
                            </div>

                            <div className="half-width">
                                <label htmlFor="bankPostcode">Bank Postcode </label>
                                <input type="text" id='bankPostcode' value={bankPostcode} onChange={handleDirectDebit} name='bankPostcode' className={`direct-details-input uk-form-width-medium uk-input ${error ? 'error-border' : ''}`} />
                            </div>
                        </div>

                        <div className="direct-debit-flex">

                            <div className="half-width">
                                <label htmlFor="bankAddress1">Bank Address Line 1 </label>
                                <input type="text" id='bankAddress1' value={bankAddress1} onChange={handleDirectDebit} name='bankAddress1' className={`direct-details-input uk-form-width-medium uk-input ${error ? 'error-border' : ''}`} />
                            </div>

                            <div className="half-width">
                                <label htmlFor="bankAddress2">Bank Address Line 2 </label>
                                <input type="text" id='bankAddress2' value={bankAddress2} onChange={handleDirectDebit} name='bankAddress2' className='direct-details-input uk-form-width-medium uk-input' />
                            </div>
                        </div>
                        <div className="direct-debit-flex">
                            <div className="half-width">
                                <label htmlFor="bankTown">Bank Town</label>
                                <input type="text" id='bankTown' value={bankTown} onChange={handleDirectDebit} name='bankTown' className={`direct-details-input uk-form-width-medium uk-input ${error ? 'error-border' : ''}`} />
                            </div>
                        </div>

                    </>
                    {directDebitError ? <p className='error__message' >We couldn't find a match, please enter your details manually!</p> : ''}
                    {
                        bankValidationError ? <p className='error__message'>You must fill both sort code and account number </p> : ''
                    }
                    <div className='buttons-container display-flex padding-top padding-bottom'>
                        <button className="back uk-margin-top" onClick={decreaseStep}> <IoIosArrowRoundBack size={24} /> Go back</button>
                        <button type="button" disabled={accountNoError || sortCodeError ? true : false}  className={`green-btn right-absolute`} onClick={() => completeDirectDebit()}>
                           {
                            loading ? <div className="spinner-quote" data-uk-spinner></div> : <span>Confirm details <IoIosArrowRoundForward size={24} /></span>
                           } 
                        </button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default WeddingDebitDetails;