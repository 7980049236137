import React from "react";
import ringIcon from "../../assets/wedding-rings-white.png";
import checked_icon from "../../assets/checkedIconWhite.webp";
import { useProductsContext } from "../../context/products-context";
import { MdOpenInNew } from 'react-icons/md'
import "./wedding-card.scss";

const WeddingCard = ({ data }) => {
  const { quoteIDEvents, addToCart, checkDemandandNeeds, increaseStep, cart, premiumFundingDetails } = useProductsContext();

  const basicInsurance = data[0];


  const completePurchase = async (quoteId, PPPId) => {
    addToCart(basicInsurance)
    await checkDemandandNeeds(quoteId, PPPId)
    increaseStep();

    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'wedding_add_to_cart', { 'event_category': 'ecommerce', 'event-label': `${basicInsurance.Title}`, 'value': `${basicInsurance.Price.toFixed(2)}` });

  }
  }


  return (

    <div className="uk-card uk-card-default uk-card-body wedding-card-container">

      <div className="price-info-background">

        <h2 className="price-box-title">{basicInsurance.Title}</h2>
        <img className="ring-icon" src={ringIcon} align="left" alt="ring-icon" />
        <p className="price-box-des">{basicInsurance.Description}</p>



        <div className="price-container">
          <div className="price-text">

            <div className="terms-and-conditions">
              <div><a
                href={basicInsurance.TC}
                className="TC"
                target="_blank"
                rel="noreferrer"
              >
                 <MdOpenInNew />&#8198;View Policy Wording
              </a></div>

            </div>

            <div className="terms-and-conditions">
              <div><a
                href={basicInsurance.KeyFacts}
                className="KF"
                target="_blank"
                rel="noreferrer"
              >
                 <MdOpenInNew />&#8198;View IPID
              </a></div>
            </div>

          </div>
        </div>
      </div>

      <div className="price-box-content">

        <div className="price-value">£{basicInsurance.Price.toFixed(2)}</div>
        <div className="price-info">Cost inc. IPT at 12%</div>
      </div>

      <div className="single-card-actions">
        <button
          className="purchase-btn"
          type="button"
          onClick={() => completePurchase(quoteIDEvents, basicInsurance.PPPId)}
        >
          <img src={checked_icon} alt="product-benefits"/> &#32;Add To Basket
        </button>
      </div>

    </div>
  );
};

export default WeddingCard;
