import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import Button from '../button/button'
import WeddingCard from '../wedding-card/wedding-card'
import { IoIosArrowRoundBack } from 'react-icons/io'

import { useProductsContext } from '../../context/products-context'
import Error from '../error/error'
import './wedding-cards-container.scss'
const WeddingCardsContainer = ({ data }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { increaseStep, decreaseStep, clearCart, cart } = useProductsContext()

  const previousStep = () => {
    clearCart()
    decreaseStep()
  }

  const [products, setProducts] = useState([]);

  useEffect(() => {


    setProducts(data)
  }, [])


  return (<>

    <div className="checkout-cards-content" id='products'>
      {
        products.length !== 0 ? (
          <div data-uk-grid="true" className="uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-match uk-grid-small uk-grid uk-text-center uk-flex-center">

            {
              products.map((item, index) => {
                return <div key={index}>
                  <WeddingCard data={item} />
                </div>
              })
            }
          </div>
        ) : <div className="error-paragraph"><Error /></div>
      }


    </div>



    <div className="btn_container2 btn-continue">
      <button className="back uk-margin-top" onClick={previousStep}> <IoIosArrowRoundBack size={24} /> Go back</button>

    </div>







  </>
  )
}

export default WeddingCardsContainer