import React, { useEffect, useRef } from "react"
import "./progress.scss"



const ProgressBar = ({currentStep=2, stepsList=["Your details", "Car details", "Products"], changeIndex, products}) => {

  const ref = useRef();


  // This function calculate X and Y
//   const getPosition = () => {
//     const y = ref.current.offsetTop;
//     console.log('y', y)
//   };

//     useEffect(() => {
//     getPosition();
//   }, []);

    return(
        <>
        <div id="progress-bar" className="progress-bar" >
            {/* <div className="stepper-wrapper">
            {stepsList.map((item, index) => {
                    
                return(
                    <div key={index} id="first-step" className={"stepper-item " + (currentStep > index?" completed ":"") + (currentStep==index?" active ":"")}>
                        <div className="step-counter"></div>
                        <div className="step-name" onClick={() => changeIndex(index,products)}>{item}</div>
                    </div>
                )
            
            })}
            </div> */}
        </div>
            
</>        
    )

        
    }

export default ProgressBar