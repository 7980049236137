import React from 'react'
import Layout from '../layouts'
import EventsSteps from '../components/events-checkout-steps/events-steps'
import { checkTheme } from "../utils/informations"

const WeddingCheckout = ({location}) => {

  return (
    <Layout pageTitle={`Wedding checkout - ${checkTheme().pageTitle}`}>
      <EventsSteps location={location}/>
    </Layout>
  )
}

export default WeddingCheckout