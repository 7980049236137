import React from 'react'
import './error.scss'

const Error = () => {
    return (
        <p className='error-message'>
            No product match your search criteria, please try again or contact us!
        </p>
    )
}

export default Error
