import React, { useEffect, useRef, useState } from "react";
import "./homepage-insurance-card.scss";
import { Link } from "gatsby";
import checked_icon from "../../assets/checkedIconGreen.webp";
import newImg from "../../assets/car_woman.jpg";
import BackgroundImage from "gatsby-background-image";
import { StaticImage } from "gatsby-plugin-image"

const HomeInsCard = ({ props, title, background }) => {


    const [showElement, setShowElement] = useState(false)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true)
        }, 1000);
    },
        [])

    return (
        <div className={`homepage-insurance-cards-container uk-animation-fade ${background ? background : ''}`}>
            {showElement ?
                <div className={`homepage-insurance-cards `}>
                    {
                        title ? <h2>{title}</h2> : ""
                    }
                    <div className="uk-child-width-1-2@m uk-grid-small uk-grid-match home-ins-grid" uk-grid="true">
                        {
                            props.map((item, index) => {

                                var bgFluidImage = {
                                    fluid: {
                                        srcWebp: item.imageWEBP,
                                        src: item.imagePNG
                                    }
                                }

                                return (
                                    <div key={index}>
                                        <BackgroundImage
                                            Tag="section"
                                            fluid={bgFluidImage.fluid}
                                            role="img"
                                            keepStatic="true"

                                        >

                                            <div className={`card-insurance uk-card uk-card-body insurance-card-body`}>

                                                <h3 className={`insurance-card-title`}>{item.title}</h3>
                                                <p className="subtitle-card">{item.subtitle}</p>
                                                {
                                                    item.benefits.map((benefits, benefitIndex) => {
                                                        return (
                                                            <div className="check-points" key={`benefit-${benefitIndex}`}>
                                                                <img className="check-img" alt="product-benefits" src={checked_icon} align="left" />
                                                                <p>{benefits}</p>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <div className="button-quote">
                                                    <Link to={`${item.link}`} className="get-quote">Get A Quote →</Link>
                                                </div>

                                            </div>
                                        </BackgroundImage>

                                    </div>

                                )


                            })
                        }
                    </div>
                </div>
                : ""}

        </div>

    )
}
export default HomeInsCard;