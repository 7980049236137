import React, { useEffect } from "react";
import { useProductsContext } from "../../context/products-context";
import ProgressBar from "../progress-bar/progress";
import { eventsProgressBar, insuranceCard } from "../../utils/informations";
import PersonalDetails from "../personal-details-wedding/personal-details";
import WeddingCardsContainer from "../wedding-checkout-cards-container/wedding-cards-container";
import WeddingEligibilities from "../wedding-eligiblities/wedding-eligibilities";
import HomeInsCard from "../homepage-insurance-card/homepage-insurance-card";
import "../your-details/your-details.scss";
import { useState } from "react";
import { navigate } from "gatsby"
import OrderComplete from "../order-complete/order-complete";
const EventsSteps = ({ location }) => {
  const {
    step,
    decreaseStep,
    weddingProducts,
    cart,
    clearCart,
    changeStepIndex,
    changeWeddingIndexAfterRedirect,
    updateCartAfterRedirect,
  } = useProductsContext();



  const [storedRedirectFlag, setStoredRedirectFlag] = useState(typeof window !== "undefined" && window.sessionStorage.getItem('redirectFlagEvent') ? JSON.parse(sessionStorage.getItem('redirectFlagEvent')) : '')

  useEffect(() => {

    if (storedRedirectFlag) {
      changeWeddingIndexAfterRedirect(2)
      updateCartAfterRedirect()
    }


  }, [storedRedirectFlag])

  useEffect(() => {
    // reload page to pass data from sessionStorage to context from sessionStorage (init-basket)
    if (!window.location.hash && window.location.pathname === "/wedding-checkout" && typeof window !== "undefined" && window.sessionStorage.getItem('quoteDetailsEvent')) {
      
        window.location = window.location + '#loaded';
        window.location.reload();

      }
  }, [])


  

  //when removing a selected item, redirect the user to previous step

  const removeProduct = () => {
    clearCart();
    if (storedRedirectFlag) {
      changeWeddingIndexAfterRedirect(0)
      if (typeof window !== "undefined" && window.sessionStorage) {
        window.sessionStorage.setItem("redirectFlagEvent", JSON.stringify(false));
      }

      navigate(`/wedding-checkout`);
    } else {
      decreaseStep();
    }
  };




  let products;

  

  return (
    <div className="padding-top wedding-padding">
      <ProgressBar
        currentStep={step}
        stepsList={eventsProgressBar}
        changeIndex={changeStepIndex}
        products={Object.values(weddingProducts)}
      />
      {step === 0 && <PersonalDetails location={location} />}
     
      {step === 1 && (
        <WeddingCardsContainer data={Object.values(weddingProducts)} />
      )}
      {step === 2 && (
        <WeddingEligibilities data={cart} clearCart={removeProduct} />
      )}

      {step === 3 ?
        // <FinalPurchase purchaseCode={purchaseCode} formStep={step} total_amount={cart.Price} />
        <>
          <OrderComplete formStep={step} total_amount={cart.Price}/>
          <HomeInsCard props={insuranceCard} title="Did you know that we also offer" background="grey"/>
        </>

        : ""}
    </div>
  );
};

export default EventsSteps;
